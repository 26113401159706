<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="form"
  >
    <b-form @submit.prevent="handleSubmit(doSubmitNext())">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Tambah':'Edit' }} Fasilitas</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <VStepTab 
          :step.sync="stepTab" 
          :last="stepLast" 
          :config="stepValidate"
          :draftBtn="true"
          @doSaveBtn="doSaveDraft()"
          :hideSubmit="row.ld_completed_status=='Y'?true:false"
        >
          <template slot="link">
            <VStepLink :step.sync="stepTab" :option="1">
              Pengajuan Pinjaman
            </VStepLink>
            <VStepLink :step.sync="stepTab" :option="2">
              Jaminan
            </VStepLink>
            <VStepLink :step.sync="stepTab" :option="3">
              Dokumen
            </VStepLink>
            <VStepLink :step.sync="stepTab" :option="4">
              Status
            </VStepLink>
          </template>
          <template slot="content">
            <VStepContent :step.sync="stepTab" :option="1">
              <template slot="title">
                Pengajuan Pinjaman
              </template>
              <template slot="fieldset">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Tipe Debitur<small class="txt_mandatory">*</small></label>
                      <v-select placeholder="Pilih Tipe Debitur" v-model="row.ld_debitur_type" :options="$parent.mrDebiturType" label="text" :reduce="v=>v.value" @input="suggestDebitur('clear')"></v-select>
                      <VValidate 
                        name="Tipe Debitur" 
                        v-model="row.ld_debitur_type" 
                        :rules="mrValidation.ld_debitur_type"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div v-if="row.ld_debitur_type" class="form-group">
                      <label>Debitur <small class="txt_mandatory">*</small></label>
                      <!--  -->
                      <v-select 
                        @search="suggestDebitur('search', $event)" 
                        @open="suggestDebitur('search', row.debitur_name||'')"
                        @input="changeDebitur"
                        v-model="row.ld_debitur_id" 
                        placeholder="Cari &amp; Pilih Debitur" 
                        :options="$parent.mrDebitur" label="text" 
                        :clearable="true" :reduce="v=>v.value"
                      >
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching && search.length">
                            Tidak ditemukan untuk pencarian <em>{{ search }}</em>.
                          </template>
                          <span v-else style="opacity: 0.5">Ketikkan nama debitur untuk mencari.</span>
                        </template>
                      </v-select>
                      <VValidate 
                        name="Debitur" 
                        v-model="row.ld_debitur_id" 
                        :rules="mrValidation.ld_debitur_id"
                      />
                    </div>
                  </div>
                  <div class="w-100"></div>
                  <div class="col-md-4 col-lg-3">
                    <div class="form-group">
                      <label>Fasilitas Pembiayaan<small class="txt_mandatory">*</small></label>
                      <v-select placeholder="Pilih Tipe Fasilitas" v-model="row.ld_loan_type" :options="$parent.mrFasilitasType" label="text" :reduce="v=>v.value" ></v-select>
                      <VValidate 
                        name="Tipe Fasilitas" 
                        v-model="row.ld_loan_type" 
                        :rules="mrValidation.ld_loan_type"
                      />
                    </div>
                    <div v-if="row.ld_loan_type===0 || row.ld_loan_type==='0'" class="form-group">
                      <label>Nama Fasilitas<small class="txt_mandatory">*</small></label>
                      <b-form-input @input="$parent.removeMultiSpace($event,'ld_loan_type_other')" v-model="row.ld_loan_type_other" placeholder="e.g Modal Kerja" />
                      <VValidate 
                        name="Nama Fasilitas" 
                        v-model="row.ld_loan_type_other" 
                        rules="required"
                      />
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-2">
                    <div class="form-group">
                      <label>Tenor</label>
                      <div class="input-group">
                        <input type="number" class="form-control" placeholder="e.g 12" v-model="row.ld_tenor" min="0">
                        <span class="input-group-append">
                          <span class="input-group-text">Bulan</span>
                        </span>
                      </div>
                      <VValidate 
                        name="Tenor" 
                        v-model="row.ld_tenor" 
                        :rules="mrValidation.ld_tenor"
                      />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Estimasi Tanggal Jatuh Tempo</label>
                      <v-select placeholder="Pilih Tanggal Jatuh Tempo" :options="Config.datePickerOnlyDate" v-model="row.ld_jatuh_tempo"></v-select>

                          <!--
                          <datepicker input-class="form-control transparent" minimum-view="day"  placeholder="Jatuh Tempo" :format="DatePickerFormat" :language="id"
                          class="my-datepicker" calendar-class="my-datepicker_calendar" 
                          v-model="row.ld_jatuh_tempo"
                          >
                          </datepicker>
                          <div class="input-group-append calendar-group">
                          <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                          </div>
                          --> 
                      <!--
                      <b-form-datepicker v-model="row.ld_est_disbursed_date" :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }" locale="id"  />
                      -->
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Estimasi Tanggal Pencairan</label>
                      <div class="input-group mb-3">
                          <datepicker input-class="form-control transparent" placeholder="Tanggal Akta Perubahan Terakhir"
                          class="my-datepicker" calendar-class="my-datepicker_calendar" 
                          v-model="row.ld_est_disbursed_date"
                          >
                          </datepicker>
                          <div class="input-group-append calendar-group">
                          <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                          </div>
                      </div>  
                      <!--
                      <b-form-datepicker v-model="row.ld_est_disbursed_date" :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }" locale="id"  />
                      -->
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Tujuan Pembiayaan</label>
                      <b-form-input @input="$parent.removeMultiSpace($event,'ld_tujuan_pembiayaan')" v-model="row.ld_tujuan_pembiayaan" placeholder="e.g Modal kerja untuk project MMS" />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label>Estimasi Biaya Provisi</label>
                      <div class="input-group">
                        <span class="input-group-prepend">
                          <span class="input-group-text">Rp</span>
                        </span>
                        <money :disabled="$parent.isPageSlugTXT!=='add'&&row.ld_biaya_provisi_old?true:false" v-bind="money" v-model="row.ld_biaya_provisi" class="form-control" placeholder="e.g 1000.0000" />
                        <VValidate 
                          name="Estimasi Biaya Provisi" 
                          :value="(row.ld_biaya_provisi||'')"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Estimasi Jumlah Pembiayaan <small class="txt_mandatory">*</small></label>
                      <div class="input-group">
                        <span class="input-group-prepend">
                          <span class="input-group-text">Rp</span>
                        </span>
                        <money  v-bind="money" v-model="row.ld_loan_amount" class="form-control" placeholder="e.g 1000.0000" />
                        <VValidate 
                          name="Estimasi Jumlah Pembiayaan" 
                          :value="(row.ld_loan_amount||'')"
                          :rules="mrValidation.ld_loan_amount"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-2">
                    <div class="form-group">
                      <label>Rate</label>
                      <div class="input-group">
                        <input type="number" v-model="row.ld_rate" class="form-control" placeholder="e.g 12">
                        <span class="input-group-append">
                          <span class="input-group-text">%</span>
                        </span>
                      </div>
                      <VValidate 
                        name="Rate" 
                        v-model="row.ld_rate" 
                        :rules="mrValidation.ld_rate"
                      />
                    </div>
                  </div>
                  <div class="w-100"></div>
                  <!--
                  <template v-if="row.ld_finish_status=='Y'">
                    <div v-if="row.ld_debitur_id" class="col-md-4">
                      <b-form-group>
                        <label>Nomor Debitur </label>
                        <b-form-input v-model="row.debitur_no" :disabled="row.old_debitur_no?true:false"></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-4">
                      <b-form-group>
                        <label>Nomor Fasilitas </label>
                        <b-form-input v-model="row.ld_no_fasilitas"/>
                      </b-form-group>
                    </div>
                    <div class="col-md-4">
                      <b-form-group>
                        <label>Nomor PPUP </label>
                        <b-form-input v-model="row.ld_no_ppup"/>
                      </b-form-group>
                    </div>
                  </template>
                  -->
                  <div class="col-md-4" v-if="!$parent.user.isIDOfficer">
                    <div class="form-group">
                        <label for="">Pilih ID Officer <small class="txt_mandatory">*</small></label>
                        <v-select @search="searchIDOfficer($event)" @open="clearIDOfficer" v-model="row.ld_id_officer" placeholder="Pilih ID Officer" 
                        :disabled="$parent.pageSlug !== 'add'" :options="$parent.mrIDOfficer" label="text" :clearable="true" :reduce="v=>v.value">
                            <template v-slot:no-options="{ search, searching }">
                                <template v-if="searching && search.length > 2">
                                  Tidak ada hasil untuk <em>{{ search }}</em
                                    >.
                                </template>
                                <span v-else style="opacity: 0.5">Input 3 karakter untuk mencari ID Officer.</span>
                            </template>
                        </v-select>
                        <VValidate 
                            name="ID Officer" 
                            v-model="row.ld_id_officer" 
                            :rules="{required: 1}"
                        />
                    </div>
                  </div>
                </div>
              </template>
            </VStepContent>
            <VStepContent :step.sync="stepTab" :option="2">
              <template slot="title">
                Jaminan
              </template>
              <template slot="fieldset">
                <div v-for="(v,k) in row.collateral||[]" :key="k" class="wrap_doc_type mb-3">
                  <div class="btn_delete_wrap">
                    <button @click="row.collateral.splice(k,1)" type="button" class="btn btn-outline-danger-100 text-danger btn-icon"><i class="icon-trash"></i></button>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="wrap_wizard_add">
                        <div class="form-group">
                          <label>Jenis Jaminan<small class="txt_mandatory">*</small></label>
                          <v-select placeholder="Pilih Jenis Jaminan" :options="mrCollateral" :value="v.collateral_id" @input="getFieldJaminan($event, v,k)" label="text" :reduce="v=>v.value" ></v-select>
                          <VValidate :name="'Jenis Jaminan ke-'+(k+1)" v-model="v.collateral_id" rules="required" />
                        </div>
                      </div>
                    </div>
                    <div v-if="v.collateral_id && v.data" class="col-md-8">
                      <Collateral :data="v.data" />
                    </div>
                  </div>
                </div>
                <button @click="row.collateral.push({data:null, collateral_id:null})" type="button" class="btn btn-outline alpha-purple text-purple-800"><i class="icon-plus3"></i> Tambah Jaminan</button>
              </template>
            </VStepContent>
            <VStepContent :step.sync="stepTab" :option="3">
              <template slot="title">
                Dokumen 
              </template>
              <template slot="fieldset">
                <div class="row">
                  <div 
                    v-for="(v,k) in row.document||[]" :key="k" 
                    :class="'col-sm-'+( k == row.document.length-1 && row.document.length%2 != 0 ? '12' : '6')"
                  >
                    <div class="wrap_doc_type">
                      <div class="btn_delete_wrap">
                        <button @click="row.document.splice(k,1)" type="button" class="btn btn-outline-danger-100 text-danger btn-icon"><i class="icon-trash"></i></button>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Jenis Dokumen <small class="text-danger">*</small></label>
                            <v-select placeholder="Pilih Jenis Dokumen" v-model="v.document_id" :options="$parent.mrDocumentType" label="text" :reduce="v=>v.value" ></v-select>
                            <VValidate 
                              :name="'Jenis Dokumen ke-'+(k+1)"
                              v-model="v.document_id" 
                              rules="required"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group result_tab">
                            <label>File Dokumen <small class="text-danger">*</small></label>
                            <div class="clearfix"></div>
                            <Uploader v-model="v.file" isDocument type="doc_umum"/>
                            <VValidate 
                              :name="'File Dokumen ke-'+(k+1)"
                              v-model="v.file" 
                              rules="required"
                            />
                          </div>
                        </div>
                        <div class="col-12">
                          <b-form-group>
                            <label>Remarks</label>
                            <b-form-textarea v-model="v.remarks"></b-form-textarea>
                          </b-form-group>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <button @click="row.document.push({
                      'document_id':null,
                      'file':null,
                      'remarks':null
                    })" type="button" class="btn btn-light alpha-purple text-purple-800"> <i class="icon-plus3"></i> Tambah Dokumen</button>
                  </div>
                </div>
              </template>
            </VStepContent>
            <VStepContent :step.sync="stepTab" :option="4">
              <template slot="title">
                Status
              </template>
              <template slot="fieldset">
                <div class="box_upload_img mb-3">
                  <div class="form-group mb-3">
                    <label>Remarks</label>
                    <textarea v-model="row.ld_complete_remarks" rows="4" cols="3" class="form-control" placeholder="Isi remarks..."></textarea>
                  </div>
                </div>
              </template>
            </VStepContent>
          </template>
        </VStepTab>
      </b-card-body>
    </b-form>
  </validation-observer>
</b-card>
</template>

<script>
//import $ from 'jquery'
import {Money} from 'v-money'
const _ = global._
import $ from 'jquery'

import Gen from '@/libs/Gen'
import VStepTab from '@/components/VStep/VStepTab.vue'
import VStepLink from '@/components/VStep/VStepLink.vue'
import VStepContent from '@/components/VStep/VStepContent.vue'
import Datepicker from 'vuejs-datepicker'
import {id} from 'vuejs-datepicker/dist/locale'
import Collateral from './Collateral.vue'

export default{
  components:{
    VStepTab, VStepLink, VStepContent, 
    Money, Collateral,Datepicker
  },
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object
  },
  data(){
    return {
      stepTab:1,
      stepLast:4,

      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked:false
      },
      DatePickerFormat: 'dd',
      id: id      

    }
  },
  computed:{
    mrCollateral(){
      return _.filter(
        JSON.parse(JSON.stringify(this.$parent.mrCollateral)),
        {debiturType: this.row.ld_debitur_type}
      )
    },
    stepValidate(){
      return {
        disabled:true,
        validate:true,
        refs:this.$refs
      }
    }
  },
  methods:{
    getFieldJaminan(selected, val, key, isConfirm=true){
      if(isConfirm && val.source_id){
        this.$swal({
          title: "Yakin mengubah jenis jaminan?",
          html:"Isian akan ter-reset setiap perubahan jenis jaminan.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText:  "Ya",
          cancelButtonText: "Batalkan",
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false
        }).then(result => {
          if(result.value){
            this.getFieldJaminan(selected, val, key, false)
          }
        })  
      }else if(selected){
        const collateral = _.find(this.mrCollateral, {value: selected})||{}
        
        this.$set(val, 'collateral_id', selected)
        this.$set(val, 'data', JSON.parse(collateral.field))
        this.$set(this.row.collateral, key, val)
      }else{
        this.$set(val, 'collateral_id', null)
        this.$set(val, 'data', null)
        this.$set(this.row.collateral, key, val)
      }
    },
    changeDebitur(){
      if(this.row.ld_debitur_id){
        Gen.apiRest(
            '/do/BoLoan', 
          {
            data: { 
              type:'autofill-officer', 
              debiturType: this.row.ld_debitur_type, 
              id: this.row.ld_debitur_id
            }
          }, 'POST'
        ).then(res=>{ 
          this.$parent.mrIDOfficer = res.data.data
          this.row.ld_id_officer = res.data.idOfficer
        })
      }
    },
    suggestDebitur(type, params=false){
      if(type=="search"){
        if(params.length){
          Gen.apiRest(
            '/do/BoLoan', 
            {
              data: { 
                type:'suggest-debitur', 
                debiturType: this.row.ld_debitur_type, 
                search: params 
              }
            }, 'POST'
          ).then(res=>{ 
            this.$set(this.$parent, 'mrDebitur', res.data.data.data)
          })
        }
      }else{
        this.$parent.mrDebitur = []
        this.$set(this.row, 'ld_debitur_id', null)
      }
    },
    doSubmitNext(){
      this.row.isDraft = "N"
      this.doSubmitCRUD('form', this.$refs)
    },
    doSaveDraft(){
      this.row.isDraft = this.row.ld_completed_status!='Y' ? "Y" : "N"
      this.doSubmitCRUD('form', this.$refs)
    },
    clearIDOfficer(){
        this.$parent.mrIDOfficer = []
        this.row.ld_id_officer = null
    },
    searchIDOfficer(e){
        if(e.length > 2){
            Gen.apiRest('/do/'+this.$parent.modulePage, {data: {type:'select-id-officer', search: e}}, 'POST').then(res=>{
                this.$parent.mrIDOfficer = res.data.data
            })
        }
    },
    doSubmitCRUD(IDForm='VForm', refs=false, typeSubmit=false){
      console.log("heres")
      return this.$parent.doSubmit(
          "/do/"+this.$parent.modulePage, 
          _.assign(
            {type:typeSubmit||(this.$parent.isAdd?'add':'update')}, 
            {id: (this.$parent.pageId||this.$parent.pageSlug)}, 
            _.clone(this.$parent.row)
          ), 
          (type, resp)=>{
            if(type=='success'){
              setTimeout(()=>{
                this.$router.push({name:this.$route.name})
              },1000)
            }else{
              if(resp.response.status==422) {
                if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                  var msg = ""
                  $.each(Object.keys(resp.response.data),(i,v)=>{
                    msg = msg+" <br/>"+v+" : "+_.values(resp.response.data[v])
                  })
                  return this.$swal("Blocked With Validation!", msg)
                }else{
                  return this.$swal("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              }else if(resp.response.data.status=='error-exp'){
                this.$swal({
                  title: resp.response.data.title || resp.response.data.message,
                  text: resp.response.data.title ? resp.response.data.message : '',
                  icon: 'error',
                  confirmButtonText: 'Lihat Detail'
                }).then(resultSwal=>{
                  if (resultSwal.value) {
                    this.$router.push({name : 'BoDebiturPerusahaan', params: {pageSlug: resp.response.data.data}})              
                  }
                })           
              }else if(resp.response.status==400 && resp.response.data.status!=='error-exp'){
                return this.$swal({
                  title: resp.response.data.title||resp.response.data.message,
                  html: resp.response.data.title?resp.response.data.message:"",
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', IDForm, refs
      )
    },
  },
  watch:{
    'row.ld_debitur_id'(n){
      if(n && this.$parent.mrDebitur.length){
        const deb = _.find(this.$parent.mrDebitur, {value: n})||{}
        this.$set(this.row, 'debitur_name', deb.text)
        this.$set(this.row, 'debitur_no', deb.no_debitur=="0"?null:deb.no_debitur)
        this.$set(this.row, 'isi_debitur_no', null)
      }else if(this.$parent.mrDebitur.length){
        this.$set(this.row, 'debitur_name', null)
        this.$set(this.row, 'debitur_no', null)
        this.$set(this.row, 'isi_debitur_no', null)
      }
    }
  }
}
</script>