<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="form"
  >
    <b-form v-if="bucket" @submit.prevent="handleSubmit(doSubmitBC())">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">
              Submit Bucket 
              <span class="badge bg-blue-400 ml-2">{{ bucket.name }}</span>
            </h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <div class="wrap_doc_type overflow-hidden p-0">
          <div v-if="bucket.document_bucket.length" class="heading_line mt-0 mb-0">
            <h4>Dokumen Bucket</h4>
          </div>
          <div class="card-body">
            <div v-if="bucket.document_bucket.length" class="row">
              <div class="col-lg-9">
                <div class="row">
                  <div v-for="(v,k) in bucket.document_bucket" :key="k" class="col-md-4 mb-2">
                    <div class="result_tab form-group">
                      <label>
                        {{ v.document_name }} 
                        <small v-if="v.is_required=='Y' && row.isDraft!='Y' && form.action=='CONFIRM'" class="text-danger">*</small> 
                      </label>
                      <div class="clearfix"></div>
                      <Uploader v-model="v.file" isDocument type="doc_umum"/>
                      <VValidate 
                        v-if="v.is_required=='Y' && row.isDraft!='Y' && form.action=='CONFIRM'"
                        :name="'Dokumen ke-'+(k+1)"
                        v-model="v.file" 
                        rules="required"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div v-if="extract(bucket.wording).length" class="card card-body border border-primary-300" v-html="bucket.wording"></div>
            </div>

            <div v-if="bucket.is_disbursement=='Y'" class="row">
              <div class="col-lg-9">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Tanggal Pencairan <small class="text-danger">*</small></label>
                      <!--
                      <b-form-datepicker v-model="row.ld_disbursed_date" :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }" locale="id"  />
                      -->
                      <div class="input-group mb-3">
                          <datepicker input-class="form-control transparent" placeholder="Jatuh Tempo"
                          class="my-datepicker" calendar-class="my-datepicker_calendar" 
                          v-model="row.ld_disbursed_date"
                          >
                          </datepicker>
                          <div class="input-group-append calendar-group">
                          <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                          </div>
                      </div>  
                      <VValidate 
                        v-if="row.isDraft!='Y'"
                        name="Tanggal Pencairan" 
                        v-model="row.ld_disbursed_date"
                        rules="required"
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Jumlah Pencairan <small class="text-danger">*</small></label>
                      <money  v-bind="{ decimal: ',', thousands: '.', prefix: '', suffix: '', precision: 0, masked:false }" v-model="row.ld_disbursed_amount" class="form-control" placeholder="e.g 1000.0000" />
                      <VValidate 
                        v-if="row.isDraft!='Y'"
                        name="Jumlah Pencairan" 
                        :value="(row.ld_disbursed_amount||'')"
                        rules="required"
                      />
                    </div>
                  </div>
                  <!--
                  <div v-if="!row.old_no_debitur" class="col-md-4">
                    <b-form-group>
                      <label>Nomor Debitur <small class="text-danger">*</small></label>
                      <b-form-input v-model="row.no_debitur"/>
                      <VValidate 
                        v-if="row.isDraft!='Y'"
                        name="Nomor Debitur" 
                        v-model="row.no_debitur"
                        rules="required"
                      />
                    </b-form-group>
                  </div>
                  <div class="col-md-4">
                    <b-form-group>
                      <label>Nomor Fasilitas <small class="text-danger">*</small></label>
                      <b-form-input v-model="row.ld_no_fasilitas"/>
                      <VValidate 
                        v-if="row.isDraft!='Y'"
                        name="Nomor Fasilitas" 
                        v-model="row.ld_no_fasilitas"
                        rules="required"
                      />
                    </b-form-group>
                  </div>
                  <div class="col-md-4">
                    <b-form-group>
                      <label>Nomor PPUP <small class="text-danger">*</small></label>
                      <b-form-input v-model="row.ld_no_ppup"/>
                      <VValidate 
                        v-if="row.isDraft!='Y'"
                        name="Nomor PPUP" 
                        v-model="row.ld_no_ppup"
                        rules="required"
                      />
                    </b-form-group>
                  </div>
                  -->
                </div>
              </div>
            </div>

            <div class="form-group">
              <div>
                <label >Action<small class="text-danger">*</small></label> <!-- dropdown list -->
              </div>
              <b-form-select v-model="form.action" class="col-md-3">
                <b-form-select-option :value="null">Pilih Opsi</b-form-select-option>
                <b-form-select-option value="CONFIRM">Submit</b-form-select-option>
                <b-form-select-option v-if="$parent.mrBucket.length && bucket.canbe_return=='Y'" value="RETURN">Return</b-form-select-option>
                <template v-if="bucket.canbe_drop=='Y'">
                  <b-form-select-option v-if="bucket.drop_type=='CANCEL'" value="CANCEL">Cancel</b-form-select-option>
                  <b-form-select-option v-if="bucket.drop_type=='REJECT'" value="REJECT">Reject</b-form-select-option>
                </template>
              </b-form-select><br/>
              
              <!--
              <b-form-radio-group v-model="form.action">
                <b-form-radio value="CONFIRM">Submit</b-form-radio>
                <b-form-radio v-if="$parent.mrBucket.length && bucket.canbe_return=='Y'" value="RETURN">Return</b-form-radio>
                <template v-if="bucket.canbe_drop=='Y'">
                  <b-form-radio v-if="bucket.drop_type=='CANCEL'" value="CANCEL">Cancel</b-form-radio>
                  <b-form-radio v-if="bucket.drop_type=='REJECT'" value="REJECT">Reject</b-form-radio>
                </template>
              </b-form-radio-group>
              -->
              <VValidate 
                v-if="row.isDraft!='Y'"
                name="Bucket Submission"
                v-model="form.action" 
                rules="required"
              />
            </div>

            <!-- Jika Approve -->
            <!-- <div class="alert alert-info mb-0">Pembiayaan ini akan dilanjutkan menuju Bucket <span class="badge badge-warning">Proposal</span></div> -->
            <!-- Jika Return  -->
            <div v-if="$parent.mrBucket.length && bucket.canbe_return=='Y' && form.action=='RETURN'" class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label >Return to Bucket <small class="text-danger">*</small></label>
                  <v-select placeholder="Pilih Bucket" v-model="form.return_to_bucket" :options="$parent.mrBucket" label="text" :reduce="v=>v.value" ></v-select>
                  <VValidate 
                    v-if="row.isDraft!='Y'"
                    name="Return to Bucket" 
                    v-model="form.return_to_bucket"
                    rules="required"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label >Return Reason<small class="text-danger">*</small></label>
                  <v-select placeholder="Pilih Alasan" v-model="form.reason" :options="Config.mr.returnReason" label="text" :reduce="v=>v.value" ></v-select>
                  <VValidate 
                    name="Return Reason" 
                    v-model="form.reason"
                    rules="required"
                  />
                </div>
              </div>
            </div>

            <div v-if="$parent.mrDropReason.length && ( form.action=='CANCEL'||form.action=='REJECT' )" class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label >Alasan Drop <small class="text-danger">*</small></label>
                  <v-select placeholder="Pilih Alasan" v-model="form.drop_reason_id" :options="$parent.mrDropReason" label="text" :reduce="v=>v.value" ></v-select>
                  <VValidate 
                    v-if="row.isDraft!='Y'"
                    name="Reason Drop" 
                    v-model="form.drop_reason_id"
                    rules="required"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label >Remarks </label>
                  <b-textarea v-model="form.remarks" placeholder="e.g. Upload dokumen proposal"></b-textarea>
                  <!-- <VValidate 
                    v-if="row.isDraft!='Y'"
                    name="Remarks" 
                    v-model="form.remarks"
                    rules="required"
                  /> -->
                </div>
              </div>
            </div>
            
            <div class="mt-3 pt-3 border-top">
              <div class="text-right">
                <button type="button" @click="doSaveBC()" class="btn bg-blue-400 mr-2">Save</button>
                <button type="button" @click="doSubmitBC()" class="btn bg-success-400 btn-labeled btn-labeled-right">Submit<b><i class="icon-checkmark"></i></b></button>
              </div>
            </div>
          </div>
        </div>

        <div class="heading_line">
          <div class="d-flex justify-content-between align-items-center">
            <h4>Informasi Pinjaman</h4>
            <router-link :to="{name: $route.name, params: {pageSlug:'detail', pageId: row.ld_id}}" class="btn btn-outline-primary">Lihat Informasi Lengkap Pinjaman</router-link>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-bordered">
            <tbody>
              <template v-if="row.ld_debitur_type=='CORPORATE'">
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Bentuk Badan Usaha</h4>
                      <p>{{ row.kodeBU.mkb_desc_id||'-' }}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Nama Badan Usaha</h4>
                      <p>{{ row.debitur_name }}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Bidang Usaha</h4>
                      <p>{{ row.bidangUsaha.mbu_desc_id||'-' }}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Nomor Debitur</h4>
                      <p>{{ row.dp_no_debitur||'-' }}</p>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-if="row.ld_debitur_type=='INDIVIDU'">
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Nama Debitur</h4>
                      <p>{{ row.debitur_name }}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Nomor Debitur</h4>
                      <p>{{ row.di_no_debitur||'-' }}</p>
                    </div>
                  </td>
                </tr>
              </template>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Nomor Fasilitas</h4>
                    <p>{{ row.ld_no_fasilitas||'-' }}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Jumlah Pembiayaan</h4>
                    <p>IDR {{ (row.ld_disbursed_amount||row.ld_loan_amount||0).format() }}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Pencairan Pembiayaan</h4>
                    <p>IDR {{ (row.ld_disbursed_amount||0).format() }}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Fasilitas Pembiayaan</h4>
                    <p>{{ row.loan_type_name||'-' }}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div class="result_tab">
                    <h4>Tujuan Pembiayaan</h4>
                    <p>{{ row.ld_tujuan_pembiayaan||'-' }}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="result_tab">
                    <h4>Rate</h4>
                    <p>{{ row.ld_rate ? parseFloat(row.ld_rate)+' %' : '-' }}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Tenor</h4>
                    <p>{{ row.ld_tenor ? row.ld_tenor+' bulan' : '-' }}</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card-body>
    </b-form>
  </validation-observer>

  <b-modal 
    v-model="modalOpen"
    :title="'Data yang belum lengkap'"
    size="lg"
    hide-footer
    body-class="p-0"
  >
    <b-card-body>
      <b-row>
        <b-col md="12">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>No</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v,k) in (dataModal||[])" :key="k">
                <td>{{k+1}}</td>
                <td>{{v}}</td>
              </tr>
            </tbody>
            <tbody v-if="!(dataModal||[]).length">
              <tr>
                <td colspan="4">
                  <div class="text-center">
                    <h4 align="center"><span>Tidak ada data</span></h4>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-card-body>
  </b-modal>
</b-card>
</template>

<script>
import {Money} from 'v-money'
import Datepicker from 'vuejs-datepicker'
import $ from 'jquery'
import _ from 'lodash'
import GlobalVue from '@/libs/Global.vue'

export default{
  extends: GlobalVue,
  components:{Money,Datepicker},
  props:{
    row:Object
  },
  computed:{
    bucket(){
      return this.row.current
    },
    form(){
      return this.row.form
    }
  },
  data(){
    return {
      modalOpen : false,
      dataModal : []
    }
  },
  methods:{
    extract(s, space=false){
      var span= document.createElement('span')
      span.innerHTML= s
      if(space) {
        var children= span.querySelectorAll('*')
        for(var i = 0 ; i < children.length ; i++) {
          if(children[i].textContent)
            children[i].textContent+= ' '
          else
            children[i].innerText+= ' '
        }
      }
      return [span.textContent || span.innerText].toString().replace(/ +/g,' ')
    },
    doSubmitBC(){
      this.$set(this.row, 'isDraft', "N")
      setTimeout(()=>{
        this.doSubmitCRUD('form', this.$refs, "bucket")
      }, 50)
    },
    doSaveBC(){
      this.$set(this.row, 'isDraft', "Y")
      setTimeout(()=>{
        this.doSubmitCRUD('form', this.$refs, "bucket")
      }, 50)
    },

    doSubmitCRUD(IDForm='VForm', refs=false, typeSubmit=false){
      console.log("heres")
      return this.$parent.doSubmit(
          "/do/"+this.$parent.modulePage, 
          _.assign(
            {type:typeSubmit||(this.$parent.isAdd?'add':'update')}, 
            {id: (this.$parent.pageId||this.$parent.pageSlug)}, 
            _.clone(this.$parent.row)
          ), 
          (type, resp)=>{
            if(type=='success'){
              setTimeout(()=>{
                this.$router.push({name:this.$route.name})
              },1000)
            }else{
              if(resp.response.status==422) {
                if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                  var msg = ""
                  $.each(Object.keys(resp.response.data),(i,v)=>{
                    msg = msg+" <br/>"+v+" : "+_.values(resp.response.data[v])
                  })
                  return this.$swal("Blocked With Validation!", msg)
                }else{
                  return this.$swal("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              }else if(resp.response.data.status=='error-list'){
                this.$swal({
                  title: resp.response.data.title || resp.response.data.message,
                  text: resp.response.data.title ? resp.response.data.message : '',
                  icon: 'error',
                  confirmButtonText: 'Lihat Detail'
                }).then(resultSwal=>{
                  if (resultSwal.value) {
                    this.modalOpen = true
                    this.dataModal = resp.response.data.data
                  }
                })           
              }else if(resp.response.data.status=='error-exp'){
                this.$swal({
                  title: resp.response.data.title || resp.response.data.message,
                  text: resp.response.data.title ? resp.response.data.message : '',
                  icon: 'error',
                  confirmButtonText: 'Lihat Detail'
                }).then(resultSwal=>{
                  if (resultSwal.value) {
                    // params: {pageSlug: data.item[idKey]}
                    this.$router.push({name : 'BoDebiturPerusahaan', params: {pageSlug: resp.response.data.data}})              
                  }
                })           
              }else if(resp.response.status==400 && resp.response.data.status!=='error-list' && resp.response.data.status!=='error-exp'){
                return this.$swal({
                  title: resp.response.data.title||resp.response.data.message,
                  html: resp.response.data.title?resp.response.data.message:"",
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', IDForm, refs
      )
    },
  }
}
</script>