<template>
<li class="nav-item" @click="doClick">
  <a href="javascript:;" :class="linkClass" data-toggle="tab">
    <slot></slot>
  </a>
</li>
</template>

<script>

export default{
  name:'VTabLink',
  props:{
    step:{
      default:1,
      type:[Number,String]
    },
    option:{
      default:1,
      type:[Number,String]
    }
  },
  computed:{
    linkClass(){
      var a ='nav-link '
      if(this.step==this.option)
        a += 'active '
      return a
    }
  },
  methods: {
    doClick(){
      this.$emit('update:step', this.option)
    }
  },
}
</script>