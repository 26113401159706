<template>
<span>
  <!-- <b-badge v-if="row.ld_completed_status=='Y'" variant="success">Completed</b-badge> -->
  <b-badge v-if="row.ld_completed_status=='N'" variant="warning">Incomplete</b-badge>
  <b-badge v-if="row.ld_drop_status=='Y'" variant="danger">Drop ({{ row.ld_drop_type }})</b-badge>
  <b-badge v-if="row.ld_finish_status=='Y'" variant="success">Investment</b-badge>

  <div v-if="row.ld_completed_status=='Y' && row.ld_finish_status!='Y'" class="mt-1">
    <div v-for="(v,k) in row.buckets" :key="k" class="mb-1">
      <b-badge 
        :variant="v.color"
        v-b-tooltip.hover 
        v-if="( 
          row.buckets.length==1 || 
          ( row.buckets.length>1 && v.finish_status=='N' ) 
        )"
        class="mr-1"
      >{{ v.name }}</b-badge>
    </div>
  </div>
  <template v-else>
    <div class="mt-1" v-if="row.ld_finish_status!=='Y' && row.ld_drop_status!=='Y'">
      <b-badge variant="info">Initiation (Hot Prospect)</b-badge>
    </div>  
  </template>
</span>
</template>

<script>
  export default {
    props:{
      row:Object
    }
  }
</script>