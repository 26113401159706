<template>
<b-card no-body>
  <div v-if="isOpen">
      <img :src="$parent.uploader(srcFiles)" alt="" class="previewImage" srcset="">
      <a href="javascript:;" @click="closePopup">
      <i class="icon-cross closeImage"></i>
      </a>
  </div>
  <div class="card-body p-0">
    <VTab v-if="row" :justify="true">
      <template slot="link">
        <VTabLink :step.sync="tab" :option="1">Informasi Debitur</VTabLink>
        <VTabLink :step.sync="tab" :option="2">Informasi Pembiayaan</VTabLink>
        <VTabLink :step.sync="tab" :option="3">Dokumen</VTabLink>
        <VTabLink :step.sync="tab" :option="4">Riwayat Bucket</VTabLink>
        <VTabLink :step.sync="tab" :option="6">Survey/Visit</VTabLink>
        <VTabLink :step.sync="tab" :option="5">Submit Komentar</VTabLink>
      </template>
      <template slot="content">
        <VTabContent :step.sync="tab" :option="1">
          <div class="pb-2 pr-2 pl-2" v-if="row.ld_debitur_type == 'CORPORATE'">
            <div class="table-responsive">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Nama Debitur</h4>
                        <p>{{row.debitur_name || "-"}}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Bentuk Badan Usaha</h4>
                        <p>{{row.kodeBU.mkb_desc_id ||"-"}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Nama Badan Usaha</h4>
                        <p>{{row.debitur_name || "-"}}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Bidang Usaha</h4>
                        <p v-if="Object.keys(row.bidangUsaha).length">{{row.bidangUsaha.mbu_desc_id||"-"}}</p>
                        <p v-else> - </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Tahun Berdiri </h4>
                        <p v-if="row.dp_tgl_akta_pendirian">{{row.dp_tgl_akta_pendirian | moment("YYYY")}} </p>
                        <p v-else> - </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Jumlah Karyawan Tetap </h4>
                        <p>{{row.dp_karyawan_ttp || "-"}} </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Jumlah Karyawan Tidak Tetap
                        </h4>
                        <p>{{row.dp_karyawan_tdk_ttp || "-"}} </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Email Perusahaan </h4>
                        <p>{{row.dp_kontak_email || "-"}} </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>No Telepon</h4>
                        <p>{{row.dp_kontak_telp?"+62":""}}{{row.dp_kontak_telp || "-"}} </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>No Debitur</h4>
                        <p>
                        {{row.no_debitur||"-"}}                        
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="clearfix"></div>
            <div class="heading_line">
              <h4>Alamat Perusahaan</h4>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Provinsi</h4>
                        <p>{{row.dp_tempat_usaha_prv || "-"}} </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Kota / Kabupaten </h4>
                        <p>{{row.dp_tempat_usaha_city || "-"}} </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Kecamatan </h4>
                        <p>{{row.dp_tempat_usaha_kec || "-"}} </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Kelurahan </h4>
                        <p>{{row.dp_tempat_usaha_kel || "-"}} </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Kode Pos</h4>
                        <p>{{row.dp_tempat_usaha_kode_pos || "-"}} </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Alamat Lengkap</h4>
                        <p>{{row.dp_tempat_usaha_alamat || "-"}}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            
            <template v-for="(v,k) in row.pemegangSaham">
              <div class="display-c" :key="k">
                <div class="heading_line">
                  <h4> Pemegang Saham #{{k+1}}
                  </h4>
                </div>
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Sapaan</h4>
                            <p>{{v.dps_sapaan || "-"}}</p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Nama Lengkap</h4>
                            <p>{{v.dps_fullname || "-"}}</p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Jabatan </h4>
                            <p>{{v.dps_jabatan || "-"}}</p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Jumlah Saham </h4>
                            <p>{{v.dps_jml_saham_percentage || "-"}} {{v.dps_jml_saham_percentage?"%":""}}</p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>No Handphone</h4>
                            <p>{{v.dps_no_phone?"+62":""}}{{v.dps_no_phone || "-"}}</p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Email </h4>
                            <p>{{v.dps_email || "-"}}</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>
          </div>

          <div class="pb-2 pr-2 pl-2" v-if="row.ld_debitur_type == 'INDIVIDU'">
            <div class="table-responsive">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Nama Lengkap</h4>
                        <p>{{row.di_sapaan}} {{row.debitur_name || "-"}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Nomor Identitas</h4>
                        <p>
                        {{row.di_nomor_identitas||"-"}}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Tempat Lahir</h4>
                        <p>
                        {{row.di_tempat_lahir||"-"}}                        
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Tanggal Lahir </h4>
                          <span v-if="row.di_tanggal_lahir">{{row.di_tanggal_lahir| moment("DD MMMM YYYY")}}</span>       <span v-else>-</span>              
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>No NPWP </h4>
                          <span>{{row.di_npwp||"-"}}</span>              
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>File NPWP </h4>
                          <a :href="$parent.uploader(row.di_npwp_file)" target="_blank">
                            <div class="upload_wrapper_pict">
                                <div class="file-upload_pict">
                                    <label for="upload" class="file-upload__label_pict">
                                        <i class="icon-eye"></i> Lihat file
                                    </label>
                                    <button id="upload" class="file-upload__input_pict" name="file-upload"></button>
                                </div>
                            </div>                           
                          </a>              
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>No Identitas</h4>
                        <p>
                        {{row.di_nomor_identitas||"-"}}                        
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Pendidikan</h4>
                        <p>
                        {{row.pendidikan.mp_name||"-"}}                        
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>No Debitur</h4>
                        <p>
                        {{row.no_debitur||"-"}}                        
                        </p>
                      </div>
                    </td>
                  </tr>
                 
                </tbody>
              </table>
            </div>
            <div class="heading_line">
              <h4> Informasi Pekerjaan
              </h4>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Bidang Usaha</h4>
                        <p v-if="row.di_bu">{{row.bidangUsaha.mbu_desc_id || "-"}}</p>
                        <p v-else>-</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Tempat Bekerja</h4>
                        <p>
                        {{row.di_tempat_bekerja||"-"}}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Alamat Tempat Bekerja</h4>
                        <p>
                        {{row.di_alamat_tempat_bekerja||"-"}}                        
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Penghasilan Kotor Pertahun </h4>
                          <span v-if="row.di_penghasilan_kotor_year">{{row.di_penghasilan_kotor_year||"-"}}</span>       <span v-else>-</span>              
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Sumber Penghasilan </h4>
                          <span v-if="row.di_kode_penghasilan=='1'">Gaji</span>
                          <span v-if="row.di_kode_penghasilan=='2'">Usaha</span>              
                          <span v-if="row.di_kode_penghasilan=='3'">Lainnya</span> 
                          <span v-else>-</span>              
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Jumlah Tanggungan</h4>
                        {{row.di_jml_tanggungan||"-"}} 
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Status Kawin</h4>
                        <span v-if="row.di_sts_kawin=='1'">Kawin</span>
                        <span v-if="row.di_sts_kawin=='2'">Belum Kawin</span>              
                        <span v-if="row.di_sts_kawin=='3'">Cerai</span> 
                        <span v-else>-</span>              
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Nama Ibu Kandung</h4>
                        <p>
                        {{row.di_fullname_ibu||"-"}}                        
                        </p>
                      </div>
                    </td>
                  </tr>
                 
                </tbody>
              </table>
            </div>
            <div class="heading_line">
              <h4> Identitas Pasangan
              </h4>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Nomor Identitas Pasangan</h4>
                        <p>
                        {{row.di_no_identitas_pasangan||"-"}}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Nama Pasangan</h4>
                        <p>
                        {{row.di_fullname_pasangan||"-"}}
                        </p>
                      </div>
                    </td>
                    
                    <td>
                      <div class="result_tab">
                        <h4>Tanggal Lahir Pasangan</h4>
                        <p>
                        {{row.di_tanggal_lahir_pasangan|moment("DD MMMM YYYY")}}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            
            <div class="heading_line">
              <h4> Informasi Kontak
              </h4>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>No Telepon</h4>
                        <p>
                        {{row.di_kontak_telp||"-"}}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>No Telepon Selular</h4>
                        <p>
                        {{row.di_kontak_cell||"-"}}
                        </p>
                      </div>
                    </td>
                    
                    <td>
                      <div class="result_tab">
                        <h4>Email</h4>
                        <p>
                        {{row.di_kontak_email||"-"}}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            
            <div class="heading_line">
              <h4> Alamat Sesuai KTP
              </h4>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Provinsi</h4>
                        <p>{{row.di_prov || "-"}} </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Kota / Kabupaten </h4>
                        <p>{{row.di_city || "-"}} </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Kecamatan </h4>
                        <p>{{row.di_kec || "-"}} </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Kelurahan </h4>
                        <p>{{row.di_kel || "-"}} </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Kode Pos</h4>
                        <p>{{row.di_postal_code || "-"}} </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Alamat Domisili</h4>
                        <p>{{row.di_alamat_domisili || "-"}}</p>
                        
                        <h4>Alamat KTP</h4>
                        <p>{{row.di_alamat_ktp || "-"}}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </VTabContent>
        <VTabContent :step.sync="tab" :option="2">
          <div class="pb-2 pr-2 pl-2">
            <div class="heading_line">
              <h4>Informasi Pembiayaan
              </h4>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Jumlah Pembiayaan</h4>
                        <p>IDR {{ parseInt(row.ld_loan_amount||'0').toLocaleString('id-ID') }}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Tenor</h4>
                        <p>{{row.ld_tenor}} Bulan</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Pencairan Pembiayaan</h4>
                        <template v-if="row.ld_disbursed_amount">
                          <p>
                          IDR {{ parseInt(row.ld_disbursed_amount||'0').toLocaleString('id-ID') }}
                          </p>
                        </template>
                        <template v-else>
                            -
                        </template>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Tujuan Pembiayaan</h4>
                        <p>{{row.ld_tujuan_pembiayaan || "-"}}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Tanggal Pencairan</h4>
                        <p v-if="row.ld_disbursed_date">
                        {{ row.ld_disbursed_date | moment("DD MMMM YYYY")}}
                        </p>
                        <p v-else>-</p>
                      </div>
                    </td>
                    <td>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Fasilitas Pembiayaan</h4>
                        <p v-if="row.ld_loan_type">
                        {{row.fasilitasPembiayaan.mfp_name || "-"}}
                        </p>
                        <p v-else> - </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Tingkat Bunga</h4>
                        <p>{{row.ld_rate}}% eff p.a </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Nomor Fasilitas</h4>
                        <p>
                        {{row.ld_no_fasilitas||"-"}}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Nomor PPUP</h4>
                        <p>{{row.ld_no_ppup||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="row.ld_drop_status == 'Y'">
                    <td>
                      <div class="result_tab">
                        <h4>Status Drop</h4>
                        <b-badge v-if="row.ld_drop_type=='REJECT'" variant="danger">REJECT</b-badge>
                        <b-badge v-if="row.ld_drop_type=='CANCEL'" variant="danger">CANCEL</b-badge>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Alasan Drop</h4>
                        <p>
                        {{row.drop_reason||"-"}}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--
            {{row.collateral_view}}
            -->
            <template v-if="(row.collateral_view||[]).length">
              <template v-for="(v,k) in (row.collateral_view||[])">
                <div class="display-c" :key="k">
                  <div class="heading_line">
                    <h4>Jaminan #{{k+1}}
                    </h4>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <tbody>
                        <tr v-for="(v1,k1) in v['data']" :key="k1">
                          <td v-for="(v2,k2) in v['data'][k1]" :key="k2">
                            <div class="result_tab">
                              <h4>{{v2.label || "-"}}</h4>
                              <template v-if="v2.type == 'checkbox'">
                                <b-badge v-for="(v3,k3) in v2.value" :key="k3"
                                  variant="success"
                                >{{v3}}</b-badge>
                              </template>
                              <template v-else-if="v2.type == 'file'">
                                <a :href="$parent.uploader(v2.value)" target="_blank">
                                  <div class="upload_wrapper_pict">
                                      <div class="file-upload_pict">
                                          <label for="upload" class="file-upload__label_pict">
                                              <i class="icon-eye"></i> Preview file
                                          </label>
                                          <button id="upload" class="file-upload__input_pict" name="file-upload"></button>
                                      </div>
                                  </div>                           
                                </a>
                              </template>
                              <template v-else>
                                <p>{{v2.value||"-"}}</p>
                              </template>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </VTabContent>
        <VTabContent :step.sync="tab" :option="3">
          <VTab :justify="true" horizontalClass="nav-tabs-solid border-0 mb-0">
            <template slot="link">
              <VTabLink :step.sync="tabDoc" :option="1">Dokumen Debitur</VTabLink>
              <VTabLink :step.sync="tabDoc" :option="2">Dokumen Pembiayaan</VTabLink>
            </template>
            <template slot="content">
              <VTabContent :step.sync="tabDoc" :option="1">
                <div class="p-2">
                  <div class="heading_line" v-if="row.ld_debitur_type == 'CORPORATE'">
										<h4>Dokumen Debitur</h4>
									</div>
                  <div class="table-responsive" v-if="row.ld_debitur_type == 'CORPORATE'">
                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>No NPWP Perusahaan</h4>
                              <p>{{row.dp_npwp||"-"}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>File NPWP</h4>
                              <div class="upload_wrapper_pict" v-if="row.dp_npwp_file">
                                <a :href="$parent.uploader(row.dp_npwp_file)" target="_blank">
                                    <div class="file-upload_pict">
                                    <label for="upload" class="file-upload__label_pict">
                                        <i class="icon-eye"></i> Preview file
                                    </label>
                                    <button id="upload" class="file-upload__input_pict" name="file-upload"></button>
                                    </div>
                                </a>  
                              </div>  
                              <span v-else>-</span>
                            </div>   
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>No SIUP</h4>
                              <p>{{row.dp_siup||"-"}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>File SIUP</h4>
                              <div class="upload_wrapper_pict" v-if="row.dp_siup_file">
                                <a :href="$parent.uploader(row.dp_siup_file)" target="_blank">
                                    <div class="file-upload_pict">
                                    <label for="upload" class="file-upload__label_pict">
                                        <i class="icon-eye"></i> Preview file
                                    </label>
                                    <button id="upload" class="file-upload__input_pict" name="file-upload"></button>
                                    </div>
                                </a>  
                              </div>  
                              <span v-else>-</span>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Tanggal Pendaftaran SIUP</h4>
                              <p v-if="row.dp_siup_reg_date">
                              {{ row.dp_siup_reg_date | moment("DD MMMM YYYY")}}
                              </p>
                              <p v-else>-</p>
                            </div>                          
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Tanggal Pendaftaran SIUP</h4>
                              <p v-if="row.dp_siup_exp_date">
                                {{ row.dp_siup_exp_date | moment("DD MMMM YYYY")}}                    
                              </p>
                              <p v-else>-</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>No TDP</h4>
                              <p>{{row.dp_tdp||"-"}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>File TDP</h4>
                              <div class="upload_wrapper_pict" v-if="row.dp_tdp_file">
                                <a :href="$parent.uploader(row.dp_tdp_file)" target="_blank">
                                    <div class="file-upload_pict">
                                    <label for="upload" class="file-upload__label_pict">
                                        <i class="icon-eye"></i> Preview file
                                    </label>
                                    <button id="upload" class="file-upload__input_pict" name="file-upload"></button>
                                    </div>
                                </a>  
                              </div>  
                              <span v-else>-</span>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Tanggal Pendaftaran TDP</h4>
                              <p v-if="row.dp_tdp_reg_date">
                              {{ row.dp_tdp_reg_date | moment("DD MMMM YYYY")}}
                              </p>
                              <p v-else>-</p>
                            </div>                          
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Tanggal Pendaftaran TDP</h4>
                              <p v-if="row.dp_tdp_exp_date">
                                {{ row.dp_tdp_exp_date | moment("DD MMMM YYYY")}}                    
                              </p>
                              <p v-else>-</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>No SK Domisili</h4>
                              <p>{{row.dp_sk||"-"}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>File SK Domisili</h4>
                              <div class="upload_wrapper_pict" v-if="row.dp_sk_file">
                                <a :href="$parent.uploader(row.dp_sk_file)" target="_blank">
                                    <div class="file-upload_pict">
                                    <label for="upload" class="file-upload__label_pict">
                                        <i class="icon-eye"></i> Preview file
                                    </label>
                                    <button id="upload" class="file-upload__input_pict" name="file-upload"></button>
                                    </div>
                                </a>  
                              </div>  
                              <span v-else>-</span>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Tanggal Pendaftaran SK Domisili</h4>
                              <p v-if="row.dp_sk_reg_date">
                              {{ row.dp_sk_reg_date | moment("DD MMMM YYYY")}}
                              </p>
                              <p v-else>-</p>
                            </div>                          
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Tanggal Pendaftaran SK Domisili</h4>
                              <p v-if="row.dp_sk_exp_date">
                                {{ row.dp_sk_exp_date | moment("DD MMMM YYYY")}}                    
                              </p>
                              <p v-else>-</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>No NIB</h4>
                              <p>{{row.dp_nib||"-"}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>File NIB</h4>
                              <div class="upload_wrapper_pict" v-if="row.dp_nib_file">
                                <a :href="$parent.uploader(row.dp_nib_file)" target="_blank">
                                    <div class="file-upload_pict">
                                    <label for="upload" class="file-upload__label_pict">
                                        <i class="icon-eye"></i> Preview file
                                    </label>
                                    <button id="upload" class="file-upload__input_pict" name="file-upload"></button>
                                    </div>
                                </a>  
                              </div>  
                              <span v-else>-</span>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Tanggal Pendaftaran NIB</h4>
                              <p v-if="row.dp_nib_reg_date">
                              {{ row.dp_nib_reg_date | moment("DD MMMM YYYY")}}
                              </p>
                              <p v-else>-</p>
                            </div>                          
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Tanggal Pendaftaran NIB</h4>
                              <p v-if="row.dp_nib_exp_date">
                                {{ row.dp_nib_exp_date | moment("DD MMMM YYYY")}}                    
                              </p>
                              <p v-else>-</p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <template v-if="row.ld_debitur_type == 'CORPORATE'">
                    <div class="heading_line">
                      <h4>Akta Pengesahan</h4>
                    </div>
                    <template v-if="(row.dokumenPengesahan||[]).length">
                      <table class="table table-bordered">
                      <tbody>
                        <div class="display-c" v-for="(v,k) in row.dokumenPengesahan" :key="k">
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>No. Akta</h4>
                                <p>{{v.dap_no_akta}}</p>
                              </div>                            
                            </td>
                            <td>
                              <div class="result_tab">
                                <h4>No. Pengesahan</h4>
                                <p>{{v.dap_no_pengesahan}}</p>
                              </div>                            
                            </td>
                            <td>
                              <div class="result_tab">
                                <h4>Notaris</h4>
                                <p>{{v.dap_notaris}}</p>
                              </div>                            
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Tanggal Akta</h4>
                                <p>{{v.dap_tgl_akta | moment("DD MMMM YYYY")}}</p>
                              </div>                            
                            </td>
                            <td>
                              <div class="result_tab">
                                <h4>Tanggal Pengesahan</h4>
                                <p>{{v.dap_tanggal_pengesahan | moment("DD MMMM YYYY")}}</p>
                              </div>                            
                            </td>
                            <td>
                              <div class="result_tab">
                                <h4>File</h4>
                                <div class="upload_wrapper_pict" v-if="v.dap_file">
                                  <a :href="$parent.uploader(v.dap_file)" target="_blank">
                                      <div class="file-upload_pict">
                                      <label for="upload" class="file-upload__label_pict">
                                          <i class="icon-eye"></i> Preview file
                                      </label>
                                      <button id="upload" class="file-upload__input_pict" name="file-upload"></button>
                                      </div>
                                  </a>  
                                </div>  
                                <span v-else>-</span>
                              </div>                            
                            </td>
                          </tr>
                        </div>
                      </tbody>
                      </table>
                    </template>
                    <div class="row" v-else>
                      <div class="col">
                        <div class="text-center"> Tidak ada Akta Pengesahan</div>                  
                      </div>
                    </div>
                  </template>

                  <div class="heading_line">
										<h4>Data Dokumen Pendukung</h4>
									</div>
                  <template v-if="(row.dokumenDebitur||[]).length">
                    <div class="pb-2 pt-2">
                      <div class="row" v-if="(row.dokumenDebitur||[]).length">
                        <div v-for="(v,k) in (row.dokumenDebitur||[])" :key="k" class="col-md-4">
                          <div class="wrap_doc_type">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label>Jenis Dokumen</label>
                                  <p>{{ v.mdt_name }}</p>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="result_tab">
                                  <label>File</label>
                                  <a :href="$parent.uploader(v.ddp_file)" target="_blank">
                                    <div class="upload_wrapper_pict">
                                        <div class="file-upload_pict">
                                            <label for="upload" class="file-upload__label_pict">
                                                <i class="icon-eye"></i> Preview file
                                            </label>
                                            <button id="upload" class="file-upload__input_pict" name="file-upload"></button>
                                        </div>
                                    </div>                           
                                  </a>  
                                </div>
                              </div>
                             <div class="col-12">
                                <div>
                                  <label for="">Remarks</label>
                                  <p>{{ v.ddp_remarks||'-' }}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <div class="row" v-else>
                    <div class="col">
                      <div class="text-center"> Tidak ada Dokumen Pendukung</div>                  
                    </div>
                  </div>
                </div>
              </VTabContent>
              <VTabContent :step.sync="tabDoc" :option="2">
                <div class="p-2">
                  <div class="heading_line">
										<h4>Dokumen Pendukung Pembiayaan</h4>
									</div>
                  <template v-if="(row.dokumenLoan||[]).length">
                    <div class="pb-2 pt-2">
                      <div class="row" v-if="(row.dokumenLoan||[]).length">
                        <div v-for="(v,k) in (row.dokumenLoan||[])" :key="k" class="col-md-4">
                          <div class="wrap_doc_type">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label>Jenis Dokumen</label>
                                  <p>{{ v.mdt_name }}</p>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="result_tab">
                                  <label>File</label>
                                  
                                    <div class="upload_wrapper_pict">
                                        <a :href="$parent.uploader(v.ddp_file)" target="_blank">
                                            <div class="file-upload_pict">
                                            <label for="upload" class="file-upload__label_pict">
                                                <i class="icon-eye"></i> Preview file
                                            </label>
                                            <button id="upload" class="file-upload__input_pict" name="file-upload"></button>
                                            </div>
                                        </a>  
                                    </div>                           
                                </div>
                              </div>
                             <div class="col-12">
                                <div>
                                  <label for="">Remarks</label>
                                  <p>{{ v.ddp_remarks||'-' }}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <div class="row" v-else>
                    <div class="col">
                      <div class="text-center"> Tidak ada Dokumen Pembiayaan</div>                  
                    </div>
                  </div>

                  <template v-if="(row.bucket.docs||[]).length">
                    <div class="heading_line">
                      <h4>Dokumen Bucket</h4>
                    </div>
                    <div class="pb-2 pt-2">
                      <div class="row">
                        <div v-for="(v,k) in bucket.docs" :key="k" class="col-md-4">
                          <div class="wrap_doc_type">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label>Bucket</label>
                                  <p>{{ v.lbd_bucket_name }}</p>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="result_tab">
                                  <label>{{ v.lbd_document_name||'-' }} </label>
                                  <div class="clearfix"></div>
                                  <a class="btn_view" :href="$parent.uploader(v.lbd_file)" target="_blank"><i class="icon-file-text2"></i></a>
                                </div>
                              </div>
                              <!-- <div class="col-12">
                                <div>
                                  <label for="">Remarks</label>
                                  <p>{{ v.lbd_remarks||'-' }}</p>
                                </div>
                              </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </VTabContent>
            </template>
          </VTab>
        </VTabContent>
        <VTabContent :step.sync="tab" :option="4">
          <div class="p-2">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th width="50">No.</th>
                    <th>Nama Bucket</th>
                    <th colspan="2">Pengerjaan Bucket</th>
                    <th width="100">PIC</th>
                    <th width="360">Remarks</th>
                    <th width="160">Status</th>
                    <th width="160">Reason</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v,k) in bucket.logs||[]" :key="k">
                    <td>{{ k+1 }}</td>
                    <td>{{ v.lbl_bucket_name }}</td>
                    <td>
                      <div class="result_tab">
                        <h4>Dimulai pada</h4>
                        <p>{{ v.lbl_join_since | moment('DD MMM YYYY HH:mm') }}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Berakhir pada</h4>
                        <p>{{ v.lbl_performed_date | moment('DD MMM YYYY HH:mm') }}</p>
                      </div>
                    </td>
                    <td>{{ v.bu_full_name||'-' }}</td>
                    <td>{{ v.lbl_remarks||'-' }}</td>
                    <td>
                    <b-badge v-if="v.lbl_action_type=='REJECT'" variant="danger">REJECT</b-badge>
                    <b-badge v-if="v.lbl_action_type=='CANCEL'" variant="danger">CANCEL</b-badge>
                    <b-badge v-if="v.lbl_action_type=='RETURN'" variant="info">RETURN</b-badge>
                    <b-badge v-if="v.lbl_action_type=='CONFIRM'" variant="success">CONFIRM</b-badge>
                    </td>
                    <td>{{ v.lbl_reason||'-' }}</td>
                  </tr>
                  <tr v-if="!(row.bucket.logs||[]).length">
                    <td colspan="12">
                      <center>Belum ada riwayat aktifitas bucket.</center>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </VTabContent>
        <VTabContent :step.sync="tab" :option="5">
          <div class="p-2">
            <div class="heading_line">
              <h4>Riwayat Komentar pada Fasilitas ini</h4>
            </div>
            <div class="row mx-2">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th width="50">No.</th>
                      <th>Pesan</th>
                      <th>Dari</th>
                      <th width="200">Tanggal</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v,k) in row.loanComments||[]" :key="k">
                      <td>{{ k+1 }}</td>
                      <td>
                        <div class="result_tab">
                        {{ v.lcom_comment }}
                        </div>                        
                      </td>
                      <td>
                        <p>{{ v.bu_full_name }}</p>
                      </td>
                      <td>
                        <div class="result_tab">
                          <p>{{ v.lcom_submitted_date | moment("DD MMMM YYYY, HH:mm") }}</p>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!(row.loanComments||[]).length">
                      <td colspan="12">
                        <center>Belum ada riwayat komentar.</center>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
            <validation-observer
                ref="VFormComments"
                v-slot="{ handleSubmit }"
            >
              <b-form @submit.prevent="handleSubmit(doPostComment())">
                <div class="row mx-1">
                  <div class="col-md-6">
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Komentar</label>
                      <textarea rows="3" v-model="comments" cols="3" class="form-control" placeholder="Tinggalkan Komentar disini"></textarea>
                      <VValidate 
                          name="Komentar" 
                          v-model="comments"
                          :rules="{required: 1, regex:/^[^\s]+(\s+[^\s]+)*$/, min: 3, max: 128}"
                      />
                    </div>
                    <b-button class="text-right btn-rounded" type="submit" variant="primary">Kirim</b-button>
                  </div>
                </div>  
              </b-form>            
            </validation-observer>      
          </div>
        </VTabContent>
        <VTabContent :step.sync="tab" :option="6">
          <div class="p-2" v-for="(v,k) in row.laporanKunjungan" :key="k">
            <div class="heading_line">
              <h4>Visit/Survey #{{v.keyID}}</h4>
            </div>
            <div class="table-responsive">
            <table class="table table-bordered">
                <tbody>
                    <tr>
                        <td width="50%">
                            <div class="result_tab">
                                <h4>Nama Debitur</h4>
                                <p>{{v.lk_debitur_name}}</p>
                            </div>
                        </td>
                        <td width="50%">
                            <div class="result_tab">
                                <h4>Jenis Kunjungan</h4>
                                <p>
                                    <span v-if="v.lk_type == 'V'" class="badge badge-flat border-info text-info-600">Visit</span>
                                    <span v-if="v.lk_type == 'S'" class="badge badge-info">Survey</span>
                                </p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="result_tab">
                                <h4>Tgl. Kunjungan</h4>
                                <p>{{v.lk_date | moment("DD MMMM YYYY, HH:mm")}}</p>
                            </div>
                        </td>
                        <td>
                            <div class="result_tab">
                                <h4>Lokasi yang dikunjungi (Alamat)</h4>
                                <p>{{v.lk_location || "-"}}</p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="result_tab">
                                <h4>Tujuan Kunjungan</h4>
                                <p>{{v.lk_tujuan_kunjungan}}</p>
                            </div>
                        </td>
                        <td>
                            <div class="result_tab">
                                <h4>Terkait Pinjaman</h4>
                                <p v-if="v.lk_loan == 0">Tidak Terkait Pinjaman</p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="result_tab">
                                <h4>Contact Person</h4>
                                <p>{{v.lk_cp || "-"}}</p>
                            </div>
                        </td>
                        <td>
                            <div class="result_tab">
                                <h4>No. Contact Person</h4>
                                <p>{{v.lk_no_cp || "-"}}</p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="result_tab">
                                <h4>Hasil Kunjungan</h4>
                                <p>{{v.lk_hasil_kunjungan || "-"}}</p>
                            </div>
                        </td>
                        <td>
                            <div class="result_tab">
                                <h4>Follow-Up</h4>
                                <p>{{v.lk_follow_up || "-"}}</p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="result_tab">
                                <h4>Surveyor/Visitor</h4>
                                <p>{{v.visitter || "-"}}</p>
                            </div>
                        </td>
                        <td>
                            <div class="result_tab">
                                <h4>Status</h4>
                                    <span v-if="v.lk_status=='I'" class="badge badge-warning">Incomplete</span>
                                    <span v-else class="badge badge-success">Completed</span>
                            </div>
                        </td>
                    </tr>
                    <template v-if="(v.dataChunk||[]).length">
                      <tr>
                          <td colspan="2" class="table-secondary">
                              <span class="mb-0 font-weight-semibold">Dokumen Kunjungan</span>
                          </td>
                      </tr>
                      <template v-for="(v2,k2) in v.dataChunk">
                          <div class="display-c" :key="k2">
                              <tr>
                                  <td v-for="(v3,k3) in v.dataChunk[k2]" :key="k3">
                                      <div class="result_tab">
                                          <h4>Dokumen #{{v3.keyID}}</h4>
                                          <a class="btn_view mr-1"><i class="icon-file-text2"></i></a>
                                          <div class="upload_wrapper_pict" @click="openPopup(v3.lkd_file)">
                                              <div class="file-upload_pict">
                                                  <label for="upload" class="file-upload__label_pict">
                                                      <i class="icon-eye"></i> Preview file
                                                  </label>
                                                  <button id="upload" class="file-upload__input_pict" name="file-upload"></button>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="result_tab mt-2">
                                          <h4>Remarks</h4>
                                          <p>{{v3.lkd_remarks||"-"}}</p>
                                      </div>
                                  </td>
                              </tr>
                          </div>
                      </template>
                    </template>
                </tbody>
            </table>
            </div>
          </div>
          <div class="p-2" v-if="!(row.laporanKunjungan||[]).length">
            <p class="text-center">Tidak ada data Visit/Survey</p>
          </div>
        </VTabContent>
      </template>
    </VTab>
  </div>
</b-card>
</template>

<script>
import VTab from '@/components/VTab/VTab.vue'
import VTabLink from '@/components/VTab/VTabLink.vue'
import VTabContent from '@/components/VTab/VTabContent.vue'
const moment = require('moment')
import Gen from '@/libs/Gen.js'


export default {
  components:{ VTab, VTabLink, VTabContent },
  props:{
    row:Object
  },
  data(){
    return {
      tab:1,
      tabDoc:1,
      comments:"",
      
      isOpen:false,
      srcFiles:"",
      arrFiles: [],
    }
  },
  methods: {
    openPopup(files){
      this.arrFiles.push(files)
      this.srcFiles = this.arrFiles[0]
      window.open(this.$parent.uploader(this.srcFiles))
      setTimeout(()=>{
        this.srcFiles = ""
        this.arrFiles = []
      },500)
    },
    closePopup(){
      this.isOpen = false
      this.srcFiles = ""
      var el = document.getElementById("body-overlay")
      el.classList.remove("show")
    },
    momentDate(dates){
      let formatted = moment(dates).format("YYYYMMDDHHmm")
      let newDates = moment(formatted, "YYYYMMDDHHmm").fromNow()
      return newDates
    },
    doPostComment(){
      this.$refs['VFormComments'].validate().then(success => {
        if(success){
          this.$parent.loadingOverlay = true           
          let data = {
            "lcom_loan_id":this.row.ld_id,
            "lcom_comment":this.comments,
            "type": "add-comments"
          }
          return Gen.apiRest('/do/BoLoan', {data: data}, 'POST').then(res=>{
              let data = res.data
              if(data.status == 'success'){
                  this.$parent.doSetAlertForm(data)
                  this.comments = ""
                  this.$parent.apiGet()
              }
              this.$parent.loadingOverlay = false
          }).catch(err=>{
              if(err){
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title ? err.response?.data?.title : err.response?.data?.message
                  err.message = " "
                  err.messageError = err.message
              }
              this.$parent.doSetAlertForm(err)
              this.$parent.loadingOverlay = false
          })
          // this.$parent.apiGet()
          // console.log("post")
        }
      })
    }
  },
  computed:{
    bucket(){
      return this.row.bucket||{}
    }
  }
}
</script>