<template>
  <div class="content-wrapper">
    <bo-page-title />
    <div class="content pt-0">
      <b-card v-if="isList" no-body>
        <b-card-header>
          <b-row>
            <b-col lg="8">
              <b-form-row>
                <b-col lg="3">
                  <b-form-group>
                    <v-select placeholder="Tipe Debitur" @input="doFilter()" v-model="filter.debiturType" :options="mrDebiturType" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group>
                    <v-select placeholder="Semua Produk" @input="doFilter()" v-model="filter.fasilitasType" :options="mrFasilitasType" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group>
                    <v-select placeholder="Semua Bucket" @input="doFilter()" v-model="filter.currentBucket" :options="mrBucket" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-col>
            <b-col lg="4">
              <SearchInput :value.sync="filter.search" @search="doFilter" />
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body class="p-0">
          <b-table
            :fields="fields"
            :items="dataList||[]"
            :per-page="perPage"
            :primary-key="idKey"
            :busy="!dataList"
            responsive
            show-empty
            striped
          >
            <template #empty>
              <div class="text-center">
                <h4 align="center"><span v-if="filter.level || filter.search">Hasil pencarian tidak ditemukan</span>
                <span v-else>Belum ada data {{ objName }} yang terdaftar</span></h4>
              </div>
            </template>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(number)="v">
              {{(data.per_page*(data.current_page-1))+v.index+1}}
            </template>

            <template #cell(ld_created_date)="data">
              {{ data.value | moment('DD MMM YYYY') }}
            </template>
            
            <template #cell(ld_source)="data">
              {{ data.value=='MANUAL'?'Internal':'Customer Portal' }}
            </template>

            <template #cell(ld_loan_amount)="data">
              <div class="wrap_content_tb">
                <h3 v-if="data.item.ld_disbursed_amount">
                  <strong>{{ (data.item.ld_disbursed_amount||0).format() }}</strong>
                </h3>
                <h3 v-else>
                  <strong>{{ (data.value||0).format() }}</strong> <small>est.</small>
                </h3>
                <p>{{ data.item.loan_type_name }}</p>
              </div>
            </template>

            <template #cell(ld_tenor)="data">
              <span class="font-weight-semibold">{{ data.value||0 }} Bulan</span>
            </template>

            <template #cell(ld_bucket_join_since)="data">
              <template v-if="data.item.ld_completed_status=='Y'" >
                <div class="result_tab">
                  <h4>Dimulai pada</h4>
                  <p>{{ data.value | moment('DD MMM YYYY HH:mm') }}</p>
                </div>
                <span class="font-weight-semibold text-blue-700">
                  ({{ data.value | moment("from", "now", true) }} yang lalu)
                </span>
              </template>
              <template v-else>
                <div class="result_tab">
                  <h4>Dimulai pada</h4>
                  <p>{{ data.item.ld_created_date | moment('DD MMM YYYY HH:mm') }}</p>
                </div>
                <span class="font-weight-semibold text-blue-700">
                  ({{ data.item.ld_created_date | moment("from", "now", true) }} yang lalu)
                </span>
              </template>
            </template>

            <template #cell(buckets) = "data">
              <Status :row="data.item"/>
            </template>

            <template
              #cell(action)="data"
            >
              <div class="list-icons list-icons-grid">
                <router-link class="list-icons-item mr-2"
                  :to="{name: $route.name, params: {pageSlug:'detail', pageId: data.item[idKey]}}"
                  v-b-tooltip.hover title="View"
                >
                  <i class="icon-eye"></i>
                </router-link>

                <router-link class="list-icons-item mr-2"
                  v-if="moduleRole('edit')"
                  :to="{name: $route.name, params: {pageSlug:data.item[idKey]}}"
                  v-b-tooltip.hover title="Edit"
                >
                  <i class="icon-pencil5 text-info"></i>
                </router-link>

                <template v-if="data.item.ld_completed_status=='Y'">
                  <template v-if="data.item.bucket_pics.indexOf(user.levelId)>-1 && data.item.ld_drop_status!='Y' && data.item.ld_finish_status!='Y'">  
                    <!--
                    -->

                    <router-link class="list-icons-item"
                      v-if="data.item.buckets[data.item.buckets.findIndex(x=>x.pic == user.levelId)].finish_status == 'N'"
                      :to="{name: $route.name, params: {pageSlug:'bucket', pageId:data.item[idKey]}}"
                      v-b-tooltip.hover title="Follow Up Bucket"
                    >
                      <i class="text-success icon-checkmark"></i>
                    </router-link>
                  </template>

                  <a class="list-icons-item"
                    href="javascript:;"
                    v-if="data.item.ld_drop_status=='Y' && data.item.ld_drop_type=='CANCEL'"
                    v-b-tooltip.hover title="Reactive Bucket"
                    @click="reactiveBucket(data.item)"
                  >
                    <i class="text-info icon-reload-alt"></i>
                  </a>
                </template>

                <template v-if="data.item.ld_finish_status=='Y'">
                  <a href="javascript:;" class="list-icons-item text-info-600"
                      v-if="moduleRole('update_nomor')"
                      @click="openModalId(data.item['ld_id'])"
                      data-toggle="tooltip" data-placement="top" title="Update Nomor Debitur"><i
                        class="icon-file-check"></i></a>
                </template>

                <a href="javascript:;" class="list-icons-item text-info-600"
                    v-if="moduleRole('delegasi')"
                    @click="openModalDelegasi(data.item['ld_id'])"
                    data-toggle="tooltip" data-placement="top" title="Delegasi"><i
                class="icon-users"></i></a>
              </div>
            </template>
          </b-table>
        </b-card-body>
        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>10">        
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </b-card-footer>
      </b-card>
      <template v-else-if="pageSlug=='bucket'">
        <FormBucket :row.sync="row" />
      </template>
      <template v-else-if="pageSlug=='detail'">
        <Detail :row.sync="row" />
      </template>
      <template v-else>
        <Form :row.sync="row" v-bind="passToSub" />
      </template>
      
      <b-modal 
        v-model="modalOpen"
        :title="'Update Nomor Debitur'"
        size="md"
        hide-footer
        body-class="p-0"
      >
        <validation-observer
          v-slot="{ handleSubmit }" ref="VFormNoDebitur"
        >
          <b-form @submit.prevent="handleSubmit(doUpdateNomorDebitur)">
            <b-card-body>
              <div v-if="row.ld_debitur_id" class="col-md-12">
                <b-form-group>
                  <label>Nomor Debitur </label>
                  <b-form-input v-model="row.debitur_no"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12">
                <b-form-group>
                  <label>Nomor Fasilitas </label>
                  <b-form-input v-model="row.ld_no_fasilitas"/>
                </b-form-group>
              </div>
              <div class="col-md-12">
                <b-form-group>
                  <label>Nomor PPUP </label>
                  <b-form-input v-model="row.ld_no_ppup"/>
                </b-form-group>
              </div>
            </b-card-body>
            <b-card-footer>
              <b-row>
                <b-col lg="12" class="text-right">
                  <button type="button" @click="modalOpen = false" class="btn btn-link" data-dismiss="modal">Cancel</button>
                  <button type="submit" class="btn bg-primary">Save</button>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-form>
        </validation-observer>
      </b-modal>

      <b-modal 
        v-model="modalOpenDelegasi"
        :title="'Delegasi'"
        size="md"
        hide-footer
        body-class="p-0"
      >   
        <validation-observer
          v-slot="{ handleSubmit }" ref="VFormDelegasi"
        >
          <b-form @submit.prevent="handleSubmit(doUpdateDelegasi)">
            <b-card-body>
              <div class="col-md-12">
                <b-form-group>
                  <label>PIC Fasilitas</label>
                  <v-select @search="searchIDOfficer($event)" @open="clearIDOfficer" v-model="row.ld_id_officer" placeholder="Pilih ID Officer" 
                  :options="mrIDOfficer" label="text" 
                  :clearable="true" :reduce="v=>v.value">
                      <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching && search.length > 2">
                            Tidak ada hasil untuk <em>{{ search }}</em
                              >.
                          </template>
                          <span v-else style="opacity: 0.5">Input 3 karakter untuk mencari ID Officer.</span>
                      </template>
                  </v-select>
                  <VValidate 
                      name="ID Officer" 
                      v-model="row.ld_id_officer" 
                      :rules="{required: 1}"
                  />
                </b-form-group>
              </div>
            </b-card-body>
            <b-card-footer>
              <b-row>
                <b-col lg="12" class="text-right">
                  <button type="button" @click="modalOpenDelegasi = false" class="btn btn-link" data-dismiss="modal">Cancel</button>
                  <button type="submit" class="btn bg-primary">Save</button>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Status from './Status.vue'
import Form from './Form.vue'
import Detail from './Detail.vue'
import FormBucket from './FormBucket.vue'
const _ = global._
import Gen from '@/libs/Gen.js'

export default {
  extends: GlobalVue,
  components:{ Form, FormBucket, Detail, Status },
  data() {
    return {
      stepTab:1,
      stepLast:2,
      idKey:'ld_id',
      fields: [
        {
          key: 'number',
          label: '#',
        },
        {
          key: 'ld_source',
          label: 'Sumber',
        },
        {
          key: 'ld_created_date',
          label: 'Tanggal Pengajuan',
        },
        {
          key: 'debitur_name',
          label: 'Nama Debitur',
        },
        {
          key: 'nama_id_officer',
          label: 'Nama PIC',
        },
        {
          key: 'ld_loan_amount',
          label: 'Jumlah Pembiayaan (IDR)',
        },
        {
          key: 'ld_tenor',
          label: 'Tenor',
        },
        {
          key: 'buckets',
          label: 'Bucket Saat Ini',
        },
        {
          key: 'ld_bucket_join_since',
          label: 'Durasi Bucket',
        },
        {
          key: 'action',
          label: 'Aksi'        
        },
      ],
      mrDebiturType:[
        {value:'CORPORATE', text:'Perusahaan'},
        {value:'INDIVIDU', text:'Individu'},
      ],
      mrFasilitasType:[],
      mrBucket:[],
      mrCollateral:[],
      mrDocumentType:[],
      mrDebitur:[],
      mrDropReason:[],
      mrIDOfficer:[],
      modalOpen: false,
      modalOpenDelegasi: false,
    }
  },
  computed:{
    passToSub(){
      return _.assign(this.passToSubComp||{})
    }
  },
  mounted(){
    this.apiGet()
    if(this.$route.params.pageSlug){
      this.validateModuleRoleCrud()
    }
  },
  methods: {
    clearIDOfficer(){
        this.mrIDOfficer = []
        this.row.ld_id_officer = null
    },
    searchIDOfficer(e){
        if(e.length > 2){
            Gen.apiRest('/do/'+this.modulePage, {data: {type:'select-id-officer', search: e}}, 'POST').then(res=>{
                this.mrIDOfficer = res.data.data
            })
        }
    },
    openModalDelegasi(id){
      this.loadingOverlay=true
      Gen.apiRest(
          "/get/"+this.modulePage+"/"+id,
          {}
      ).then(res=>{
          _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
          })
          this.modalOpenDelegasi = true
          this.loadingOverlay=false
      })
    },
    openModalId(id){
      this.loadingOverlay=true
      Gen.apiRest(
          "/get/"+this.modulePage+"/"+id,
          {}
      ).then(res=>{
          _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
          })
          this.modalOpen = true
          this.loadingOverlay=false
      })
    },
    doUpdateDelegasi(){
      this.$refs['VFormDelegasi'].validate().then(success => {
            if(success){
                this.loadingOverlay = true
                this.row.isDraft = this.row.ld_completed_status!='Y' ? "Y" : "N"
                let data = this.row
                data.type = 'update'
                Gen.apiRest('/do/'+this.modulePage, {data: data}, 'POST').then(res=>{
                    res.data.statusType = 200                    
                    this.doSetAlertForm(res.data)
                    this.apiGet()
                    this.loadingOverlay = false
                    this.modalOpenDelegasi = false
                }).catch(err=>{
                    if(err){
                        err.statusType = err.status
                        err.status = "error"
                        err.title = err.response?.data?.title ? err.response?.data?.title : err.response?.data?.message
                        err.message = " "
                        err.messageError = err.message
                    }
                    this.doSetAlertForm(err)
                    this.loadingOverlay = false
                    this.modalOpenDelegasi = false
                })
            }
        })
    },
    doUpdateNomorDebitur(){
      this.$refs['VFormNoDebitur'].validate().then(success => {
            if(success){
                this.loadingOverlay = true
                this.row.isDraft = this.row.ld_completed_status!='Y' ? "Y" : "N"
                let data = this.row
                data.type = 'update'
                Gen.apiRest('/do/'+this.modulePage, {data: data}, 'POST').then(res=>{
                    res.data.statusType = 200                    
                    this.doSetAlertForm(res.data)
                    this.apiGet()
                    this.loadingOverlay = false
                    this.modalOpen = false
                }).catch(err=>{
                    if(err){
                        err.statusType = err.status
                        err.status = "error"
                        err.title = err.response?.data?.title ? err.response?.data?.title : err.response?.data?.message
                        err.message = " "
                        err.messageError = err.message
                    }
                    this.doSetAlertForm(err)
                    this.loadingOverlay = false
                    this.modalOpen = false
                })
            }
        })
    },
    reactiveBucket(item){
      this.$swal({
        title: "Yakin mengaktifkan loan?",
        html:"Akan dikembalikan ke bucket sebelum Cancel.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText:  "Ya",
        cancelButtonText: "Batalkan",
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false
      }).then(result => {
        if(result.value){
          this.doRest("reactive-bucket", {
            id:item.ld_id,
          }, res=>{
            console.log(res)
            location.reload()
          })
        }
      })
    }
  },
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>