<template>
<div class="wrap_wizard_add bor_left select-form">
  <template v-if="readOnly">
    READONLY
  </template>
  <div v-else class="row">
    <div v-for="(v,k) in data" :key="k" class="col-sm-6">
      <b-form-group>
        <label>{{ v.label }} <small v-if="v.isRequired" class="text-danger">*</small></label>
        <template v-if="v.type=='long-text'">
          <b-form-textarea :value="v.value" @input="update($event,k)"></b-form-textarea>
          <VValidate 
            v-if="v.isRequired"
            :name="v.label" 
            :value="v.value" 
            rules="required"
          />
        </template>
        <template v-else-if="v.type=='radio'">
          <b-form-radio-group v-model="v.value" @input="update($event,k)">
            <b-form-radio v-for="(v2,k2) in v.options||[]" :key="k2" :value="v2">{{ v2 }}</b-form-radio>
          </b-form-radio-group>
          <VValidate 
            v-if="v.isRequired"
            :name="v.label" 
            :value="v.value" 
            rules="required"
          />
        </template>
        <template v-else-if="v.type=='checkbox'">
          <b-form-checkbox-group v-model="v.value" @input="update($event,k)">
            <b-form-checkbox v-for="(v2,k2) in v.options||[]" :key="k2" :value="v2">{{ v2 }}</b-form-checkbox>
          </b-form-checkbox-group>
          <VValidate 
            v-if="v.isRequired"
            :name="v.label" 
            :value="(v.value||[]).length?1:''" 
            rules="required"
          />
        </template>
        <template v-else-if="v.type=='file'">
          <Uploader :value="v.value" @input="update($event,k)" type="docimage" :config="{'rule_type': ruleType(v) }"/>
          <VValidate 
            v-if="v.isRequired"
            :name="v.label" 
            :value="v.value" 
            rules="required"
          />
        </template>
        <template v-else>
          <b-form-input :value="v.value" @input="update($event,k)"/>
          <VValidate 
            v-if="v.isRequired"
            :name="v.label" 
            :value="v.value" 
            rules="required"
          />
        </template>
      </b-form-group>
    </div>
  </div>
</div>
</template>

<script>
const _ = global._
export default {
  props:{
    data:{
      default:()=>{ return [] },
      type:Array
    },
    readOnly:{
      default:false,
      type:Boolean
    }
  },
  methods:{
    ruleType(val){
      return _.filter(val.options||[], {checked:true}).map(v=>v.rule_type).join(",")
    },
    update(e, key){
      this.$set(this.data[key],'value', e)
      this.$emit('update:data', this.data)
      this.$emit('input', this.data)
    }
  }
}
</script>

<style>
.btn_delete_wrap{
  z-index: 2
}
</style>